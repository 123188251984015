// store/user.js
import router from "../../router";
import conexionSocket from "@/composables/conexionSocket.js";

const state = () => ({
  isLoggedIn: false,
  datosUsuario: {},
});

const getters = {
  loginState: state => state.isLoggedIn,
  datosUser: state => state.datosUsuario,
};

const mutations = {
  login(state, payload) {
    state.isLoggedIn = true;
    state.datosUsuario = payload;

    if (router.currentRoute.name === "Login") {
      router.push("/").catch(err => console.error(err));
    }
  },

  logout(state) {
    state.isLoggedIn = false;
    localStorage.removeItem("token");
    localStorage.removeItem("locationWatchId");
    router.push("/login");
  },
};

const actions = {
  async login({ commit }, userData) {
    try {
      // Solicitar permisos de ubicación
      const location = await new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocalización no disponible'));
          return;
        }

        navigator.geolocation.getCurrentPosition(
          position => resolve({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          }),
          error => reject(error),
          { enableHighAccuracy: true }
        );
      });

      // Emitir bindUser con la ubicación
      conexionSocket.emit('bindUser', {
        id_usuario: userData.id_usuario,
        nombre: userData.nombre,
        apellido: userData.apellido,
        id_rol: userData.id_rol,
        rol: userData.rol.rol,
        ubicacion: location
      });

      // Iniciar seguimiento de ubicación
      const watchId = navigator.geolocation.watchPosition(
        position => {
          conexionSocket.emit('actualizar-ubicacion', {
            id_usuario: userData.id_usuario,
            ubicacion: {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          });
        },
        error => console.error('Error en seguimiento:', error),
        { enableHighAccuracy: true }
      );

      localStorage.setItem('locationWatchId', watchId.toString());
      commit('login', userData);

    } catch (error) {
      console.error('Error al inicializar ubicación:', error);
      // Si falla la ubicación, igual hacemos login pero notificamos
      commit('login', userData);
    }
  },

  async logout({ commit, state }) {
    try {
      // Limpiar el seguimiento de ubicación
      const watchId = localStorage.getItem('locationWatchId');
      if (watchId) {
        navigator.geolocation.clearWatch(parseInt(watchId));
      }

      // Emitir evento de logout al socket
      if (state.datosUsuario.id_usuario) {
        conexionSocket.emit('logout', {
          id_usuario: state.datosUsuario.id_usuario
        });
      }

      // Esperar a que se complete la desconexión
      await new Promise(resolve => {
        conexionSocket.once('logout-completed', resolve);
        
        // Timeout por si no recibimos respuesta
        setTimeout(resolve, 1000);
      });

      // Desconectar socket
      conexionSocket.disconnect();

      // Proceder con el logout local
      commit("logout");
      
    } catch (error) {
      console.error('Error durante logout:', error);
      // Aún así procedemos con el logout local
      commit("logout");
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};