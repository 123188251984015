import axios from 'axios';

const state = {
    customers: [],
    loading: false,
    error: null
};

const getters = {
    allCustomers: state => state.customers,
    isLoading: state => state.loading,
    hasError: state => state.error,
    getCustomerById: (state) => (id) => {
        return state.customers.find(customer => customer.id_cliente === id);
    }
};

const actions = {
    async fetchCustomers({ commit }) {
        try {
            commit('SET_LOADING', true);
            const response = await axios.get(`${process.env.VUE_APP_BASE_URL}clientes/resumido`);
            commit('SET_CUSTOMERS', response.data.data);
            commit('SET_ERROR', null);
            return response.data;
        } catch (error) {
            commit('SET_ERROR', error.message || 'Error al cargar clientes');
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // Acción para buscar por ID
    async fetchCustomerById({ commit }, id) {
        try {
            commit('SET_LOADING', true);
            const response = await axios.get(`${process.env.VUE_APP_BASE_URL}clientes/resumido/${id}`);
            // Actualiza el cliente específico en el estado
            commit('UPDATE_CUSTOMER', response.data.data);
            return response.data.data;
        } catch (error) {
            commit('SET_ERROR', error.message || 'Error al cargar cliente');
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    // Otras acciones que puedas necesitar
    async updateCustomerLocation({ commit }, { id_cliente, ubicacion }) {
        try {
            commit('SET_LOADING', true);
            const response = await axios.put(`${process.env.VUE_APP_BASE_URL}cliente/ubicacion`, {
                id_cliente,
                ubicacion
            });
            commit('UPDATE_CUSTOMER', response.data);
            return response.data;
        } catch (error) {
            commit('SET_ERROR', error.message || 'Error al actualizar ubicación');
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    }
};

const mutations = {
    SET_CUSTOMERS(state, customers) {
        state.customers = customers;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    UPDATE_CUSTOMER(state, updatedCustomer) {
        const index = state.customers.findIndex(c => c.id_cliente === updatedCustomer.id_cliente);
        if (index !== -1) {
            state.customers.splice(index, 1, updatedCustomer);
        } else {
            state.customers.push(updatedCustomer);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};