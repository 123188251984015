<template>
  <div>
    <span style="cursor: pointer" class="mx-5" @click="openWhatsApp">
      <v-icon color="green">mdi-whatsapp</v-icon>
    </span>
    <v-dialog v-model="handleDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Vincular WhatsApp</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-if="loader" class="d-flex justify-center">
              <v-progress-circular
                :size="70"
                :width="7"
                color="success"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12">
              <v-img :src="imageBase64" max-height="350"></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "WhatsAppVincular",

  data() {
    return {
      loader: false,
      handleDialog: false,
      imageBase64: "",
    };
  },
  methods: {
    openWhatsApp() {
      this.handleDialog = true;
      this.requestCode();
    },
    async requestCode() {
      try {
        this.loader = true;
        let r = await axios.get(
          process.env.VUE_APP_BASE_URL + "whatsapp-status"
        );
        console.log("code ", r);
        this.imageBase64 = r.data.qrCode;
        return r.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
