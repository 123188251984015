import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../views/Inicio.vue";
import Denegado from "../views/Denegado.vue";
import errorPage from "../views/ErrorPage.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
    meta: {
      rutaName: "inicio",
    },
  },
  {
    path: "/404",
    name: "Denegado",
    component: Denegado,
  },
  {
    path: "*",
    name: "ErrorPage",
    component: errorPage,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: () => import("../views/Usuarios.vue"),
    meta: {
      rutaName: "usuarios",
    },
  },
  {
    path: "/usuarios/add",
    name: "UsuariosAdd",
    component: () => import("../views/usuarios/UserAdd.vue"),
    meta: {
      rutaName: "usuarios/add",
    },
  },
  {
    path: "/productos",
    name: "Productos",
    component: () => import("../views/productos/Productos.vue"),
    meta: {
      rutaName: "productos",
    },
  },
  {
    path: "/radar",
    name: "Radar",
    component: () => import("../views/Radar/Radar.vue"),
    meta: {
      rutaName: "radar",
    },
  },
  {
    path: "/productos/add",
    name: "ProductosAdd",
    component: () => import("../views/productos/ProductosAdd.vue"),
    meta: {
      rutaName: "productos/add",
    },
  },
  {
    path: "/productos/edit/:id",
    name: "ProductosEdit",
    component: () => import("../views/productos/ProductosEdit.vue"),
    meta: {
      rutaName: "productos/edit",
    },
  },
  {
    path: "/parametros",
    name: "Parametros",
    component: () => import("../views/Parametros.vue"),
    meta: {
      rutaName: "parametros",
    },
  },
  {
    path: "/parametros/:tipo",
    name: "ParametrosTable",
    component: () => import("../views/ParametrosTable.vue"),
    meta: {
      rutaName: "parametros/",
    },
  },
  {
    path: "/marcas/add",
    name: "marcas",
    component: () => import("../views/marcas/MarcasAdd.vue"),
    meta: {
      rutaName: "marcas/add",
    },
  },
  {
    path: "/marcas/edit/:id",
    name: "MarcasEdit",
    component: () => import("../views/marcas/MarcasEdit.vue"),
    meta: {
      rutaName: "marcas/edit",
    },
  },
  {
    path: "/categorias",
    name: "Categorias",
    component: () => import("../views/categorias/Categorias.vue"),
    meta: {
      rutaName: "categorias",
    },
  },
  {
    path: "/categorias/add",
    name: "categoriasAdd",
    component: () => import("../views/categorias/CategoriasAdd.vue"),
    meta: {
      rutaName: "categorias/add",
    },
  },
  {
    path: "/categoria/edit/:id",
    name: "CategoriaEdit",
    component: () => import("../views/categorias/CategoriasEdit.vue"),
    meta: {
      rutaName: "categoria/edit",
    },
  },
  {
    path: "/subcategorias",
    name: "SubCategorias",
    component: () => import("../views/subcategorias/SubCategorias.vue"),
    meta: {
      rutaName: "subcategorias",
    },
  },
  {
    path: "/subcategorias/add",
    name: "subcategoriasAdd",
    component: () => import("../views/subcategorias/SubCategoriasAdd.vue"),
    meta: {
      rutaName: "subcategorias/add",
    },
  },
  {
    path: "/subcategoria/edit/:id",
    name: "SubCategoriaEdit",
    component: () => import("../views/subcategorias/SubcategoriaEdit.vue"),
    meta: {
      rutaName: "subcategoria/edit",
    },
  },
  {
    path: "/proveedores",
    name: "Proveedores",
    component: () => import("../views/proveedores/Proveedores.vue"),
    meta: {
      rutaName: "proveedores",
    },
  },
  {
    path: "/proveedores/add",
    name: "proveedoresAdd",
    component: () => import("../views/proveedores/ProveedoresAdd.vue"),
    meta: {
      rutaName: "proveedores/add",
    },
  },
  {
    path: "/proveedores/edit/:id",
    name: "ProveedoresEdit",
    component: () => import("../views/proveedores/ProveedoresEdit.vue"),
    meta: {
      rutaName: "proveedores/edit",
    },
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: () => import("../views/clientes/Clientes.vue"),
    meta: {
      rutaName: "clientes",
    },
  },
  {
    path: "/clientes/add",
    name: "clientesAdd",
    component: () => import("../views/clientes/ClientesAdd.vue"),
    meta: {
      rutaName: "clientes/add",
    },
  },
  {
    path: "/clientes/edit/:id",
    name: "ClientesEdit",
    component: () => import("../views/clientes/ClientesEdit.vue"),
    meta: {
      rutaName: "clientes/edit",
    },
  },
  {
    path: "/ventas",
    name: "ventas",
    component: () => import("../views/ventas/Ventas.vue"),
    meta: {
      rutaName: "ventas",
    },
  },
  {
    path: "/admin-ventas",
    name: "admin-ventas",
    component: () => import("../views/admin-ventas/AdminVentas.vue"),
    meta: {
      rutaName: "admin-ventas",
    },
  },
  {
    path: "/admin-ventas/edit/:id",
    name: "admin-ventas-edit",
    component: () => import("../views/admin-ventas/AdminVentasEdit.vue"),
    meta: {
      rutaName: "admin-ventas/edit",
    },
  },
  {
    path: "/deposito",
    name: "deposito",
    component: () => import("../views/deposito/Deposito.vue"),
    meta: {
      rutaName: "deposito",
    },
  },
  {
    path: "/deposito/edit/:id",
    name: "deposito-edit",
    component: () => import("../views/deposito/AdminVentasEditDeposito.vue"),
    meta: {
      rutaName: "deposito/edit",
    },
  },
  {
    path: "/estadisticas",
    name: "estadisticas",
    component: () => import("../views/estadisticas/Estadisticas.vue"),
    meta: {
      rutaName: "estadisticas",
    },
  },
  {
    path: "/reportes",
    name: "reportes",
    component: () => import("../views/estadisticas/Reportes.vue"),
    meta: {
      rutaName: "reportes",
    },
  },
  {
    path: "/pagos",
    name: "pagos",
    component: () => import("../views/estadisticas/Pagos.vue"),
    meta: {
      rutaName: "pagos",
    },
  },
  {
    path: "/egresos",
    name: "egresos",
    component: () => import("../views/Egresos.vue"),
    meta: {
      rutaName: "egresos",
    },
  },
  {
    path: "/productos/actualizar_stock",
    name: "actualizar_stock",
    component: () => import("../views/productos/ActualizaStockView.vue"),
    meta: {
      rutaName: "productos/actualizar_stock",
    },
  },
  {
    path: "/compras",
    name: "compras",
    component: () => import("../views/compras/ComprasView.vue"),
    meta: {
      rutaName: "compras",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach(async (to, from, next) => {
  await setTimeout(() => {
    console.log("en el router", store.state.user.datosUsuario.id_rol);
  }, 500);

  var rutas;
  if (to.matched.some((record) => record.meta.rutaName)) {
    rutas = to.matched[0].meta.rutaName;

    if (typeof store.state.user.datosUsuario.id_rol === "undefined") {
      console.log("entro");
      next({ name: "Login" });
    } else {
      if (typeof store.state.user.datosUsuario.rutas[rutas] === "undefined") {
        console.log("el no", store.state.user.datosUsuario);
        console.log("no entro");
        next({ name: "Denegado" });
      } else {
        if (store.state.user.datosUsuario.rutas[rutas] == 1) {
          //console.log(store.state.user.datosUsuario.rutas[record.meta.rutaName]);
          next();
          return;
        }
      }
    }
  } else {
    next();
    return;
  }
});

export default router;
